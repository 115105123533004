import { GatsbyImage } from 'gatsby-plugin-image';
import { toCleanString } from 'helper/markdown';
import React from 'react';

interface Props {
  title: string;
  image: GatsbyTypes.File;
}

export const Title: React.FC<Props> = (props) => {
  const { image } = props;
  let title = props.title;
  const start = title.indexOf('**');
  title = title.replace('**', '');
  const end = title.indexOf('**', start);
  title = title.replace('**', '');

  const renderTitleUnderline = () => (
    <>
      {title.slice(0, start)}
      <span className="relative">
        {toCleanString(title.slice(start, end))}
        <GatsbyImage
          className="absolute left-0 w-22/20 top-1/3 -z-10 dark:opacity-40"
          image={image.childImageSharp!.gatsbyImageData}
          alt="Unterstrichen"
        />
      </span>
      {title.slice(end)}
    </>
  );

  return (
    <h1 className="max-w-[15ch]">
      {end != -1 ? renderTitleUnderline() : title}
    </h1>
  );
};
