import React from 'react';
import { Meta } from 'components/Meta';
import Layout from 'components/Layout';
import { toCleanString } from 'helper/markdown';
import { Title } from 'components/Title';
import 'resources/styles/templates/page.scss';

type Props = {
  pageContext: {
    item: GatsbyTypes.MarkdownRemark;
    underline: GatsbyTypes.File;
  };
};

const Page: React.FC<Props> = (props) => {
  const { underline } = props.pageContext;
  const { frontmatter, html } = props.pageContext.item;

  return (
    <Layout>
      <main className="template-page">
        <section className="my-16">
          <Title title={frontmatter?.title!} image={underline} />
        </section>
        <section
          className="mt-0 mb-60"
          dangerouslySetInnerHTML={{
            __html: html!,
          }}
        />
      </main>
    </Layout>
  );
};

export const Head = (props: Props) => {
  const { frontmatter } = props.pageContext.item;
  return <Meta title={toCleanString(frontmatter?.title!)} />;
};

export default Page;
